<template>
  <div class="incomeorder">
    <van-skeleton title :row="8" :loading="loading">
      <van-sticky>
        <van-nav-bar title="我的预约" left-arrow @click-left="goBack" />
      </van-sticky>
      <div class="empty-order" v-show="emptyOrder">
        <div class="empty-order-title">您还没有预约~</div>
      </div>
      <div class="goods" v-show="goodsOrder">
        <van-list
          v-model="loadinglist"
          :finished="finishedlist"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            class="my-card-one"
            v-for="(item, index) in orderList"
            :key="index"
          >
            <p>预约编号：{{ item.time }}</p>
            <p>{{ item.title }}</p>
            <hr />
            <p>
              预约科室：{{ item.goodsName }}
            </p>
            <p>
              预约日期：{{ item.userDate }}
            </p>
            <p>
              预约时间：{{ item.userTime }}
            </p>
            <p v-html="consumedFormat(item.consumed)"></p>
            <p class="one-time">{{ timeFormat(item.time) }}</p>
          </div>
        </van-list>
      </div>
      <van-dialog
        v-model="pactShow"
        showCancelButton
        cancelButtonText="知道了"
        confirmButtonText="去邀请"
        @confirm="toPersonal"
      >
        <div class="superteam-main">
          <div class="st-main-title">完成以下任务即可升级超级达人</div>
          <div class="st-main-box">
            <van-row>
              <van-col span="18" class="box-l">
                <h4>累计邀请达人10人</h4>
                <van-progress
                  :percentage="
                    myinfo.teamNum * 10 >= 100 ? 100 : myinfo.teamNum * 10
                  "
                  stroke-width="8"
                  :pivot-text="myinfo.teamNum + '人'"
                  pivot-color="#ee0a24"
                  color="#ee0a24"
                />
              </van-col>
              <van-col span="6" class="box-r"
                >{{ myinfo.teamNum }} / 10</van-col
              >
            </van-row>
          </div>
          <div class="st-main-prompt">
            {{
              myinfo.teamNum >= 10
                ? "您已经是超级达人啦！"
                : "再邀请" + (10 - myinfo.teamNum) + "人即可成为超级达人啦~"
            }}
          </div>
        </div>
      </van-dialog>
    </van-skeleton>
  </div>
</template>
<script>
export default {
  name: "OrdersList",
  data() {
    return {
      Authorization: localStorage.getItem(window.lsn),
      orderList: [],
      loadinglist: true,
      finishedlist: false,
      currDay: "",
      active: 1,
      skip: 0,
      limit: 10,
      loading: true,
      emptyOrder: true,
      goodsOrder: false,
      users: 0,
      pactShow: false,
    };
  },
  mounted() {
    this.init(); /* 项目初始化 */
    this.onLoad();
  },
  methods: {
    timeFormat(val) {
      let timestamp = null;
      timestamp = new Date(val);
      return (
        timestamp.toLocaleDateString().replace(/\//g, "-") +
        " " +
        timestamp.toTimeString().substr(0, 8)
      );
    },
    init() {
      let $this = null;
      $this = this;
      let yy = null;
      let mm = null;
      let dd = null;
      yy = new Date().getFullYear();
      mm = new Date().getMonth() + 1;
      if (mm.toString().length === 1) {
        mm = "0" + mm;
      }
      dd = new Date().getDate();
      if (dd.toString().length === 1) {
        dd = "0" + dd;
      }
      this.currDay = yy + "-" + mm + "-" + dd;

      // 获取个人信息
      this.axios({
        url: this.$store.state.api + "/my/info",
        method: "get",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: this.Authorization,
        },
      }).then((res) => {
        if (res.data) {
          $this.myinfo = res.data;
          $this.loading = false;
          if (!$this.myinfo.userinfo) {
            window.sign(window.location.href);
          }
        }
      });
    },
    onLoad() {
      let $this = null;
      $this = this;
      // 获取订单
      this.axios({
        url: this.$store.state.api + "/my/order",
        method: "get",
        params: {
          type: "1",
          skip: $this.skip,
          limit: $this.limit,
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: this.Authorization,
        },
      }).then((response) => {
        if (response.data) {
          if ($this.skip === 0 && response.data.length > 0) {
            $this.emptyOrder = false;
            $this.goodsOrder = true;
          }
          /* 获取订单 */
          for (let index = 0; index < response.data.length; index++) {
            const element = response.data[index];
            $this.orderList.push(element);
          }
          $this.skip = $this.skip + $this.limit;
          $this.loadinglist = false;
          if (response.data.length <= 0) {
            $this.finishedlist = true;
          }
        }
      });
    },
    goSuperTeam() {
      this.pactShow = true;
      //this.$router.push({ path: "superteam" });
    },
    consumedFormat(val) {
      if (val) {
        return "状态：<span style='color:#0bb000;'>已就诊</span>";
      } else {
        return "状态：未就诊";
      }
    },
    toPersonal() {
      this.$router.push({ path: "personal" });
    },
    goTeam() {
      this.$router.push({ path: "incometeam" });
    },
    goQr() {
      this.$router.push({
        path: "follow2/?code=" + this.myinfo.invitationCode,
      });
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>
<style lang="scss">
body {
  background: #f5f5f5;
}
.incomeorder {
  text-align: left;
  padding-bottom: 70px;
  .van-tabbar-item {
    font-size: 14px;
  }
  .van-nav-bar .van-icon-arrow-left {
    font-size: 24px;
    color: #969799;
  }
  .profit-head {
    background-color: #f94e47;
    text-align: center;
    padding: 30px 20px 60px 20px;
    border-radius: 0 0 20px 20px;
    position: relative;
    .head-tema {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 24px;
      color: #ffffff;
    }
    .head-saoma {
      position: absolute;
      top: 20px;
      left: 20px;
      font-size: 24px;
      color: #ffffff;
    }
    .head-level {
      height: 36px;
      overflow: hidden;
      border-radius: 20px;
      margin-top: 10px;
      .van-cell {
        height: 36px;
        line-height: 36px;
        padding: 0 10px;
        font-size: 12px;
        background: #ffdcb1;
        color: #000000;
        .van-cell__title {
          -webkit-box-flex: 3;
          -webkit-flex: 3;
          flex: 3;
          text-align: left;
        }
        .van-cell__value {
          -webkit-box-flex: 1;
          -webkit-flex: 1;
          flex: 1;
          color: #a47948;
        }
        .van-cell__right-icon {
          color: #a47948;
        }
        .van-cell__left-icon {
          font-size: 22px;
          margin-bottom: 3px;
        }
      }
    }
  }
  .profit-head .head-balance {
    font-size: 32px;
    color: #ffffff;
    font-weight: bold;
    line-height: 46px;
    letter-spacing: 2px;
  }
  .profit-head b {
    color: #f8c6c6;
    font-size: 14px;
    font-weight: 100;
    letter-spacing: 1px;
  }
  .tag {
    width: 90%;
    height: 66px;
    margin: -33px auto 0;
    background: #ffffff;
    border-radius: 5px;
    overflow: hidden;
    font-size: 14px;
    z-index: 99;
    position: relative;
  }
  .tag .tag-bottom {
    margin-top: 2px;
    font-size: 12px;
  }
  .tag .action {
    color: #f3352e;
  }
  .tag .action .van-grid-item__text {
    color: #f3352e;
  }
  .head b {
    display: block;
    color: #ffffff;
    font-size: 14px;
    letter-spacing: 2px;
  }
  .home-img {
    width: 4rem;
    height: 4rem;
    background-color: #f2f2f2;
    border: 1px #cccccc solid;
  }
  .empty-order {
    height: 400px;
    width: 100%;
    background: url("/img/element/noorder1.png") no-repeat center 20px;
    background-size: 68%;
  }
  .empty-order .empty-order-title {
    padding-top: 260px;
    text-align: center;
    color: #888888;
  }
  .my-card-one {
    margin: 15px 5%;
    padding: 15px 20px;
    background: #ffffff;
    border-radius: 5px;
    font-size: 12px;
    .one-time {
      text-align: right;
      margin: 0;
    }
  }
  .my-card-one hr {
    border: 0.5px #cccccc dashed;
  }
  .my-card-one p span {
    color: rgb(221, 136, 8);
  }
  .superteam-main {
    padding: 20px;
    .st-main-title {
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      padding: 0 10px;
      text-align: center;
    }
    .st-main-box {
      padding: 5px 10px;
      h4 {
        line-height: 45px;
        margin: 0;
        font-size: 14px;
      }
      .box-l {
        height: 70px;
      }
      .box-r {
        padding-top: 41px;
        text-align: center;
        color: #999999;
        font-size: 14px;
      }
    }
    .st-main-prompt {
      padding-top: 10px;
      text-align: center;
      font-size: 12px;
      color: #666666;
    }
  }
}
</style>
